body {
  background-color: #1a1a1a;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.App {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.App-header {
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meets {
  color: orange;
  font-size: 20px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.canvases-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawing-canvas {
  border: 5px solid orange;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.pre-processing-canvas {
  margin-left: 50px;
  width: 200px;
  height: 200px;
  background-color: black;
  border: 5px solid orange;
  margin-top: 15px;
}

.prediction-container {
  display: flex;
  flex-direction: column !important;
  margin-top: 50px;
}

.prediction-header {
  color: orange;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.tf-logo {
  width: 50px;
  height: 50px;
}

.predict {
  margin-left: 15px !important;
  color: orange;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 2px 2px black;
}

.circular-progress {
  margin-top: 10px;
  margin-left: 15px;
}

.prediction-rank {
  display: flex;
  margin-top: 20px;
}

.prediction-winner-container {
  padding-top: 8px;
  border: 5px solid orange;
  width: 150px;
}

.prediction-winner {
  text-align: center;
  font-size: 75px !important;
  color: black;
}

.prediction-losers {
  display: flex;
  flex-direction: column !important;
}

.loser-text {
  margin-left: 16px !important;
  font-size: 30px !important;
  color: black;
}

.loser-container {
  flex: 1;
  align-items: center;
  border: 5px solid orange;
  border-left: 2px;
  display: flex;
  padding: 8px;
}
